<template lang="html">
  <div class="ActivityMembersPage">
    <div v-if="isClient">
      <SearchBar class="SearchBarV2" :placeholder="$t('find_by_name_or_tags')" :autocompleteListItems="tags" v-on:item-selected="handleItemSelected($event)"/>
    </div>
    <div class="ActivityMembersPage--MembersTags" v-if="isClient">
      <Tag :name="t" class="TagV2" v-for="(t,i) in filterTags" isClickable isActive v-on:remove="removeFilterTag(i)"/>
    </div>
    <div class="ActivityMembersPage--MembersInfo" v-if="isClient">
      <p>{{$t('members_show_of',{membersNum:members.length,membersTotal:totalMembers})}}</p>
      <hr>
      <p v-if="notParticipateMembers>0">{{$t('members_not_participated',{members:notParticipateMembers})}}</p>
      <div v-if="isClient">
        <CommunityButton
        v-on:click="remindAllMembers()"
        v-if="isModerator && notParticipateMembers>0 && communityInfo.status != 'REVISION' && communityCurrentActivity.status != 'FINISHED'"
        > {{$t('members_remind')}}({{ notParticipateMembers }})</CommunityButton>
      </div>
      <p v-if="notParticipateMembers==0">{{$t('members_all_participated')}}</p>
    </div>
    <p class="TotalMembers">{{$t('participants_show_total',{membersTotal:communityCurrentActivity.membersNum})}}</p><br>
    <div class="ActivityMembersPage--MembersList">
      <CommunityUser :allowPublicTags="true" :hideGamification="communityInfo.hideGamification || (isUser && communityInfo.privateLabs)" :member="m" v-for="(m,i) in members" :allowRemind="isModerator" :allowTags="isModerator" v-on:remind-member="remindMember"/>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapMutations } from 'vuex';
import store from '@/store';
import Tag from '~/components/Tag';
import CommunityButton from '@/components/CommunityButton.vue'
import SearchBar from '@/components/SearchBar';
import CommunityUser from '@/components/CommunityUser'
import {
  COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS,
  COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL,
  COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS
} from '@/store/community/activities.module';
export default {
  name: 'ActivityMembersPage',
  props:{
    tags:Array
  },
  components: {
    Tag,
    CommunityButton,
    SearchBar,
    CommunityUser
  },
  computed: {
    ...mapGetters([
      'communityInfo',
      'isClient',
      'isModerator',
      'isUser',
      'communityCurrentActivity',
      'communityPublicTags',
      'communityTags',
      'onlineUsers'
    ]),
  },
  data(){
    return{
      members:[],
      notParticipateMembersTemp:[],
      filterNickname: "",
      filterTags:[],
      notParticipateMembers: 0,
      totalMembers: 0,
      page:0,
      pageSize:50,
      backupFilter:[],
      isLoadingMembers: false,
      currentRequest: null,
      debounceTimeout: null
    }
  },
  methods:{
    async remindAllMembers(){
      await store.dispatch(COMMUNITY_REMIND_CURRENT_ACTIVITY_ALL_PENDING_PARTICIPANTS, {
        activityId: this.$route.params.id
      }).then((data) => {
        Swal.fire(this.$t('msg_members_remind_sent',{members:data.length}))
      });
    },
    async remindMember(member,type){
      await store.dispatch(COMMUNITY_REMIND_CURRENT_ACTIVITY_PARTICIPANT, {
        activityId: this.$route.params.id,
        memberId:member.identifier,
      })
      if (type != "ALL") {
        Swal.fire(this.$t('msg_members_remind_sent_member',{member:member.nickname}))
      }
    },
    handleItemSelected(event) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.filterActivityMembers(event);
      }, 500);
    },
    async filterActivityMembers(filter, isPageScroll){
      if (filter == undefined || filter.text == "") {
        this.filterNickname = "";
        this.email="";
      }
      if (filter != undefined && filter.type == 'filter') {
        if (filter.text != "") {
          this.filterTags.push(filter.text)
        }
        this.filterNickname = ""
        this.email="";
      }
      let publicTags = [];
      let tags = [];
      for (let t of this.communityTags) {
        if (this.filterTags.includes(t)) tags.push(t);
      }
      for (let t of this.communityPublicTags) {
        if (this.filterTags.includes(t)) publicTags.push(t);
      }
      if (filter != undefined && filter.type == "nickname"){
         this.filterNickname = filter.text;
         this.email="";
      }
      if (filter != undefined && filter.type == "email"){
        this.email = filter.text;
        this.filterNickname = "";
      }
      if(!isPageScroll && this.page > 0){
        this.pageSize = (this.page + 1) * this.pageSize
        this.page = 0
      }
      if (filter != undefined && filter.type != "filter") {
        this.backupFilter = filter;
      } else {
        this.backupFilter = null;
      }
      this.isLoadingMembers = true

      const request = store.dispatch(this.isClient ? COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS_EL : COMMUNITY_FETCH_CURRENT_ACTIVITY_PARTICIPANTS, {
        order: "recent",
        activityId: this.$route.params.id,
        filter:tags.join(','),
        publicFilter:publicTags.join(','),
        nickname:this.filterNickname != "" ? this.filterNickname : undefined,
        page:this.page,
        pageSize:this.pageSize
      });

      this.currentRequest = request;
      const data = await request;
      if (this.currentRequest !== request) return;

      this.isLoadingMembers = false
      if(isPageScroll) this.members = this.members.concat(data.communityMembers)
      else this.members = data.communityMembers;
      for(let m of this.members){
        m.online = this.onlineUsers.testersOnlineCondensed.includes(m.identifier)
      }
      this.totalMembers = data.totalTesters
      this.setNotParticipated()
    },
    setNotParticipated(){
      this.notParticipateMembersTemp = this.members.filter(function(item){
        if (!item.hastParticipated) {
          return item
        }
      });
      this.notParticipateMembers = (this.communityCurrentActivity.membersNum - this.communityCurrentActivity.participatedTesterNum);
    },
    removeFilterTag(i){
      this.filterTags.splice(i,1)
      this.filterActivityMembers()
    },
    pageScroll(scrollTop, scrollHeight, clientHeight){
      if(this.isLoadingMembers) return
      if (scrollTop + clientHeight >= scrollHeight - 5){
        if(this.page == 0 && this.pageSize > 10){
          this.page = this.pageSize / 10
          this.pageSize = 10
        }
        else this.page++
        this.filterActivityMembers(this.backupFilter, true)
      }
    },
  },
  async mounted(){
    await this.filterActivityMembers()
  }

}
</script>
<style lang="scss" scoped>
.ActivityMembersPage{
  .TotalMembers {
    color: #747474;
  }
  &--MembersTags{
    margin-top: 10px;
  }
  &--MembersInfo{
    display: flex;
    align-items: center;
    margin: 10px 0;
    hr{
      margin: 0 10px;
      width: 1px;
      height: 15px;
    }
    button{
      background: var(--primary-color);
      font-size: 14px;
      margin-left: 20px;
    }
  }
  &--MembersList{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 5px;
    @media only screen and (max-width: 600px) {
      grid-template-columns: 100%;
    
  }
  }
}
</style>
